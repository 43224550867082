<template>
	<div class="team">
		<div class="header">
			<img src="@/assets/images/team/banner.png" alt="">
			<div class="inner animated fadeIn">
				<p>{{$t('lang.home.nav5')}}</p>
				<p>{{$t('lang.team.header1')}}</p>
			</div>
		</div>
		<div class="team_cont">
			<div class="team_tab">
				<p @click="active(0)" :id="[num==0?'active':'']" @mousemove.stop="changeActive($event)" @mouseout.stop="removeActive($event)">DIGITAL INTERMEDIATE</p>
				<!-- <p @click="active(1)" :id="[num==1?'active':'']" @mousemove.stop="changeActive($event)" @mouseout.stop="removeActive($event)">MUSIC COMPOSING<br />AND RECORDING</p> -->
			</div>
			<transition-group name="ul" class="isShow">
				<ul v-for="(item,index) in dataList" :key="item.id" v-show="index == num">
					<li class="animated fadeInUp" v-for="(list,index) in item.contText" :key="'info-'+index" @click="toLink(item.id)">
						<img :src="list.img" />
						<div class="figure_text">
							<h2>{{$t(list.name)}}</h2>
							<p v-for="(li,index) in $t(list.position)" :key="'info1-'+index">{{li}}</p>
							<div class="works title">代表作品主要有</div>
							<div class="works" v-for="(text,index) in list.works" :key="'info2-'+index">{{text}} </div>
						</div>
						<div class="to" @click="toLink(item.id)" v-if="item.id==0"></div>
					</li>
				</ul>
			</transition-group>
		</div>
	</div>
</template>
<script>
export default {
  name: "team",
  data() {
    return {
      dataList: [
        {
          id: 0,
          contText: [
            {
              img: require("../assets/images/team/liyongji.png"),
              name: "lang.team.name1",
              position: "lang.team.text1",
              works: ["杀⼈的回忆, 老男孩, 我要复仇龙⻔⻜甲, 汉江怪物 ..."]
            }
          ]
        },
        {
          id: 1,
          contText: [
            {
              img: require("../assets/images/team/zhaoguanghao.png"),
              name: "赵光浩",
              position: "lang.team.text2",
              works: [
                "2011 埃内斯库国际作曲⼤赛⼀等奖",
                "2013 ⽇内瓦国际作曲⼤赛⼀等奖",
                "2013 玛丽亚·何塞女王作曲奖 ..."
              ]
            },
            {
              img: require("../assets/images/team/lijunheng.png"),
              name: "李濬衡",
              position: "lang.team.text3",
              works: [
                "2013 2BIC⾳乐会 BIC SHOW",
                "2014 2BIC ⾳乐会真品",
                "2016 拉德卡罗特⾳乐节 - 丽⽔ ..."
              ]
            }
          ]
        }
      ],
      num: 0
    };
  },
  methods: {
    active(index) {
      this.num = index;
    },
    // 鼠标移入加入class
    changeActive($event) {
      $event.currentTarget.className = "active";
      $event.currentTarget.style.transition = ".5s";
    },
    removeActive($event) {
      $event.currentTarget.className = "";
      $event.currentTarget.style.transition = ".5s";
    },
    toLink(id) {
      if (id == 0) {
        this.$router.push("/teamDetail");
      } else {
        return;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.team {
  width: 100%;
  height: 100%;
  background: #061319;
  // font-family: "OpenSansLight";
  .team_cont {
    width: 100%;
    height: 100%;
    padding: 120px 13.54vw 100px;
    display: flex;
    align-items: top;
    justify-content: flex-start;
    .team_tab {
      width: 300px;
      height: 100%;
      > p {
        cursor: pointer;
        width: 300px;
        height: 76px;
        border-radius: 6px;
        background: #3c3c3c;
        color: #a0a0a0;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 30px;
        font-family: "OpenSansRegular";
      }
      #active {
        background: #fff;
        color: #011118;
      }
      .active {
        background: #fff;
        color: #011118;
      }
    }
    .isShow {
      width: calc(100% - 300px);
      height: 100%;
      margin: 0 0 0 40px;
      > ul {
        width: 100%;
        height: 100%;
        background: #061319;
        > li {
          width: 100%;
          height: 364px;
          margin-bottom: 40px;
          display: flex;
          position: relative;
          cursor: pointer;
          > img {
            float: 0 1 281px;
            width: 281px;
            height: 364px;
            float: left;
          }
          .figure_text {
            flex: auto;
            text-align: left;
            margin-left: 40px;
            > h2 {
              font-size: 32px;
              color: #fff;
              margin: 0;
              margin: 24px 0;
              font-family: "OpenSansRegular";
            }
            > p {
              font-size: 22px;
              font-family: "OpenSansLight";
            }
            .works {
              font-size: 18px;
              line-height: 1.5;
              font-family: "OpenSansLight";
            }
            .title {
              margin-top: 20px;
            }
          }
          .to {
            width: 68px;
            height: 40px;
            background: url(../assets/images/team/to.png) no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
      .ul-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .ul-enter-active,
      .fade-leave-active {
        transition: all 2s ease;
      }
    }
  }
}
</style>